<template>
  <div>
    <section class="bg-grey bg bg-menu">
      <b-row class="content-wrapper row">
        <b-col
          xl="5"
          md="12"
        >
          <h2 class="mt-4 mb-xl-3 text-xl-left">
            Mi cuenta > <b>Mis compras</b>
          </h2>
        </b-col>
      </b-row>
    </section>

    <section class="bg-grey bg">
      <b-row class="content-wrapper row pt-5">
        <b-col
          class="d-block m-auto"
          lg="12"
        >
          <b-row>
            <b-col
              lg="12"
              xl="4"
            >
              <b-card class="px-5">
                <div
                  class="text-center"
                >
                  <b-avatar
                    class="avatar-size"
                    variant="primary"
                  />
                  <h2 class="mt-2">
                    {{ this.$store.state.account.user.name }} <!--todo: Poner el nombre de usuario-->
                  </h2>
                  <p>Distribuidor</p> <!--todo: Poner el nombre de usuario-->
                  <ul class="ul-links">
                    <li>
                      <a
                        href="/profile"
                        @click.prevent="$router.push({ name: 'profile-information'})"
                      >Mis datos</a>
                    </li>
                    <li>
                      <a
                        href="/all-orders"
                        class="active"
                        @click.prevent="$router.push({ name: 'all-orders'})"
                      ><b>Mis compras</b></a>
                    </li>
                    <li>
                      <a
                        href="/profile-rewards"
                        @click.prevent="$router.push({ name: 'profile-rewards'})"
                      >Recompensas</a>
                    </li>
                    <!--                    <li>
                      <a
                        href="/profile"
                        target="_blank"
                      >Favoritos</a>
                    </li>-->
                  </ul>
                </div>
              </b-card>
            </b-col>
            <b-col
              lg="12"
              xl="8"
            >
              <b-card>
                <b-row>
                  <b-col
                    xl="12"
                  >
                    <h2>Últimas compras</h2>
                  </b-col>
                  <b-col
                    v-for="order in getOrders"
                    :key="order.id"
                    xl="12"
                  >
                    <div class="card-data">
                      <div class="title-card">
                        <span>Fecha de compra: <b>{{ new Intl.DateTimeFormat('es-MX').format(new Date(order.created_at)) }}</b></span>
                        <span
                          v-if="order.status === 'paid'"
                          class="text-primary float-right"
                        ><b>Pagado</b></span>
                        <span
                          v-if="order.status === 'pending'"
                          class="text-primary float-right"
                        ><b>Sin pago</b></span>
                        <span
                          v-if="order.status === 'pending_approval'"
                          class="text-primary float-right"
                        ><b>Pendiente de aprobación</b></span>
                        <span
                          v-if="order.status === 'shipped'"
                          class="text-primary float-right"
                        ><b>Enviado</b></span>
                        <span
                          v-if="order.status === 'completed'"
                          class="text-primary float-right"
                        ><b>Completada</b></span>
                        <span
                          v-if="order.status === 'cancelled'"
                          class="text-primary float-right"
                        ><b>Cancelada</b></span>
                        <span
                          v-if="order.paymentMethod === 'conekta' && order.invoice"
                          class="text-primary float-right"
                        ><b>Facturado / </b></span>
                        <span
                          v-if="order.paymentMethod === 'conekta' && !order.invoice"
                          class="text-primary float-right"
                        ><b>No Facturado / </b></span>
                      </div>
                      <div class="body-card">
                        <b-row>
                          <b-col
                            md="12"
                            lg="2"
                          >
                            <img
                              loading="lazy"
                              :src="order.items[0].product.media[0].original_url"
                              alt="Avatar"
                              class="img-fluid d-block m-auto"
                            >
                          </b-col>
                          <b-col
                            class="mb-2"
                            md="12"
                            lg="2"
                          >
                            <p><b>{{ order.items[0].name }}</b></p>
                            <p>Cantidad: {{ order.items[0].quantity }}</p>
                            <p v-if="order.items[0].variants.brand">Logo: {{
                              getVariants.filter((variant) => variant.key === 'brand' && order.items[0].variants['brand'] === variant.id)[0].value
                            }}</p>
                            <p v-if="order.items[0].variants.color">Color: {{
                              getVariants.filter((variant) => variant.key === 'color' && order.items[0].variants['color'] === variant.id)[0].value
                            }}</p>
                          </b-col>
                          <b-col
                            class="mb-2"
                            md="12"
                            lg="2"
                          >
                            <p class="">
                              <b>Dirección de envío:</b>
                            </p>
                            <p>
                              {{ order.shipping_address.name }} {{ order.shipping_address.outdoor_number }}
                              {{ order.shipping_address.address }}
                              {{ order.shipping_address.city }}, {{ order.shipping_address.state }}
                              {{ order.shipping_address.postalcode }}</p>
                          </b-col>
                          <b-col
                            class="mb-2"
                            md="12"
                            lg="3"
                          >
                            <p><b># Pedido:</b> {{ order.number }}</p>
                            <p><b>Guías:</b> <a
                              v-for="shipment in order.shipments"
                              :key="shipment.id"
                              class="text-primary"
                              :href="shipment.configuration.url_tracking"
                              target="_blank"
                            >{{ shipment.configuration.company }}: {{ shipment.tracking_number }}</a></p>
                            <p><b>Pago:</b> ${{ order.total }}</p>
                            <p><b>Método de pago:</b> {{ order.paymentMethod }}</p>
                          </b-col>
                          <b-col
                            md="12"
                            lg="3"
                          >
                            <b-button
                              variant="primary"
                              class="btn-gradient-promo mb-2 mr-1"
                            >
                              Volver a comprar
                            </b-button>

                            <b-button
                              variant="primary"
                              class="btn-detail mb-2"
                              @click="$router.push({ name: 'orders-show', params: { id: order.id }})"
                            >
                              Detalles del pedido.
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    xl="12"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="getTotalItems"
                      :per-page="getPerPage"
                      first-number
                      last-number
                      class="mb-0 mt-1"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar, BButton, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BPagination,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      search: '',
      currentPage: 1,
    }
  },
  computed: {
    getOrders() {
      return store.state.orders.orders.data
    },
    getPerPage() {
      return store.state.orders.orders.per_page
    },
    getTotalItems() {
      return store.state.orders.orders.total
    },
    getVariants() {
      return store.state.products.variants
    },
  },
  watch: {
    currentPage() {
      this.fetchOrders()
    },
  },
  beforeMount() {
    store.dispatch('products/listVariants')
    this.fetchOrders()
  },
  created() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
  methods: {
    searchProduct() {
      this.$router.push({ name: 'products', params: { searchActive: 'true' } })
    },
    fetchOrders() {
      store.dispatch('orders/index', { currentPage: this.currentPage })
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";

//New Arribals
.card-data{
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #979593;
  border-radius: 5px;
}
.title-card{
  padding: 10px;
  border-bottom: 1px solid #979593;
  background-color: #f2f2f2;
  border-radius: 5px 5px 0px 0px;
}
.body-card{
  padding: 10px;
}
.bg-menu {
  margin-top: -45px;
  background-color: #fff!important;
}
.header-navbar-shadow{
  background:none!important;
}
//New Arribals
.avatar-size{
  width: 100px;
  height: 100px;
  display: flex;
  margin: 0 auto;
}
.dark-layout{
  .card-data{
    background-color: $theme-dark-body-bg;
  }
  .ul-links{
    a{
      color: $theme-dark-body-color;
    }
  }
  .bg-menu{
    background-color: $theme-dark-card-bg!important;
  }
  .collapse-title{
    color: $theme-dark-headings-color!important;
  }
  .blockquote{
    background-color: $theme-dark-input-bg;
    border:solid 1px $theme-dark-headings-color;
  }
  .title-products{
    font-size: 30px;
    font-weight: 700;
    color: $theme-dark-headings-color!important;
  }
  .bg-grey{
    background-color: $theme-dark-body-bg;
  }
  .img-overlay {
    background-color: $theme-dark-input-bg;
  }
}
.bg {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

}
.bg-grey{
   background-color: #f2f2f2;

 }

.content-wrapper {
  margin-left: 50vw;
  margin-right: 50vw;
  box-sizing: border-box;

  > * {
    box-sizing: content-box;
  }
}
.ul-links{
  list-style: none;
  text-align: left;
  a{
    color: $dark;
  }
  .active{
    color: $primary;
  }
}
.body-card{
  p{
    margin-bottom: 0px;
  }
}
.btn-detail{
  background-color: rgba(74,34,104,1) !important;
  border: none;
}
.btn-gradient-promo{
  background: rgb(246,162,27);
  background: -moz-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  background: linear-gradient(90deg, rgba(246,162,27,1) 0%, rgba(236,44,57,1) 50%, rgba(74,34,104,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6a21b",endColorstr="#4a2268",GradientType=1);
  border: none;
}
</style>
